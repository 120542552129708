<template>
  <div class="coupon-exchange-serial">
    <PageTitle
      title="兌換碼管理"
      icon="chevron_left"
      style="padding-bottom: 24px"
      btn="匯出"
      @iconClick="goBackCouponExchangeSetting"
      @btnClick="modal.export = true"
    />
    <div v-loading="loading" />
    <template v-if="!loading">
      <div class="coupon-exchange-serial-container">
        <div class="card-container form-wrapper">
          <div class="coupon-info-block">
            <p class="card-title">兌換券資料</p>
            <div class="flex items-center">
              <div class="coupon-title">名稱:</div>
              <span>{{ formData.name }}</span>
            </div>
          </div>
        </div>
        <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
          <div class="card-container form-wrapper mb-[20px]">
            <div class="coupon-link-block">
              <p class="card-title">兌換網址設定</p>
              <el-form-item label="網址" prop="exchangeLink" required>
                <el-input v-model="formData.exchangeLink" placeholder="請填寫兌換的網站（格式如：https://www.google.com/）" />
              </el-form-item>
            </div>
          </div>
          <div class="card-container form-wrapper">
            <div class="coupon-type-block">
              <p class="card-title">兌換碼種類設定</p>
              <el-form-item label="種類" prop="exchangeType" required>
                <el-select v-model="formData.exchangeType">
                  <el-option
                    v-for="(item, index) in typeConfig"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>

        <CouponShareCodeBlock
          class="card-container form-wrapper"
          :exchangeType="formData.exchangeType"
          :couponId="formData.id"
        />
      </div>
    </template>
    <PageFixedFooter @cancel="goBackCouponExchangeSetting" @confirm="onSubmit" />

    <WarningDialog
      v-if="modal.beforeLeave"
      content="尚未完成設定兌換網址，確定是否仍要離開此頁面 ?"
      @close="modal.beforeLeave = false"
      @confirm="onConfirmLeave"
    />

    <ExportOptionsDialog
      v-if="modal.export"
      allRange
      @close="modal.export = false"
      @export="modal.export = false, prepareExport()"
    />
    <ExportDialog
      v-if="modal.exporting"
      :inProgress="false"
      :isError="false"
      :percentage="100"
      :data="[]"
      :total="0"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import store from '@/store'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router/composables'
import CouponShareCodeBlock from './components/CouponShareCodeBlock.vue'
import {
  UpdateCouponExchange,
  FindCouponExchange,
  GetCouponExchangeSerial,
  GetCouponExchangeSerialCount,
} from '@/api/coupon'
import formUtils from '@/utils/form'
import { lengthRules, noEmptyRules } from '@/validation'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { filter, find, map, get } from 'lodash'
import { ExportExcel } from '@/utils/excel'
import { getAllDataFromApi } from '@/utils/helper'
import { useWindowUnload } from '@/use/useWindowUnload'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'

export default defineComponent({
  name: 'CouponExchangeSerial',
  components: { CouponShareCodeBlock, ExportOptionsDialog, ExportDialog, WarningDialog },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const { registerWarnUnloadEvent } = useWindowUnload()
    registerWarnUnloadEvent()
    const shopId = computed(() => store.getters.shop)
    const couponId = computed(() => route.params.id)
    const formData = reactive({
      id: null,
      name: null,
      exchangeType: 'share',
      exchangeLink: null,
    })
    const formRef = ref(null)
    const formRules = {
      exchangeType: [noEmptyRules()],
      exchangeLink: [noEmptyRules(), lengthRules(1, 255)],
    }
    const loading = ref(false)

    const modal = reactive({
      export: false,
      exporting: false,
      beforeLeave: false,
    })

    const nextFuncHolder = ref(null)
    const forceRoute = ref(false)

    const onConfirmLeave = () => {
      if (nextFuncHolder.value) {
        console.log('nextFuncHolder', nextFuncHolder.value)
        nextFuncHolder.value()
        return
      }
      forceRoute.value = true
      router.push({ name: 'CouponExchangeSetting' })
    }

    const goBackCouponExchangeSetting = () => {
      if (!formData.exchangeLink) {
        modal.beforeLeave = true
        return
      }
      router.push({ name: 'CouponExchangeSetting' })
    }

    const typeConfig = [
      {
        label: '共享兌換碼',
        value: 'share',
      },
      {
        label: '獨立兌換碼',
        value: 'single',
      },
    ]

    const asyncData = (res) => {
      formData.id = res.id
      formData.name = res.name
      formData.exchangeType = res.exchangeType || 'share'
      formData.exchangeLink = res.exchangeLink
    }

    const findCouponExchange = async () => {
      loading.value = true
      const [res, err] = await FindCouponExchange({
        shopId: shopId.value,
        id: couponId.value,
      })
      loading.value = false
      if (err) {
        window.$message.err(err)
        return
      }
      asyncData(res)
    }

    const updateCouponExchange = async () => {
      loading.value = true
      const { exchangeType, exchangeLink } = formData
      const [, err] = await UpdateCouponExchange({
        shopId: shopId.value,
        id: couponId.value,
        exchangeType,
        exchangeLink,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      findCouponExchange()
      window.$message.success('更新成功！')
    }

    const onSubmit = async () => {
      const pass = await formUtils.checkForm(formRef.value)
      if (pass) updateCouponExchange()
    }

    onMounted(() => {
      findCouponExchange()
    })

    const getCouponExchangeSerialCount = async () => {
      const [res, err] = await GetCouponExchangeSerialCount({ shopId: shopId.value, CouponId: couponId.value })
      if (err) throw err
      return res.count
    }

    const getAllCouponExchangeSerial = async () => {
      let max
      try {
        max = await getCouponExchangeSerialCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        CouponId: couponId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetCouponExchangeSerial,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      return res
    }

    const getExportData = async () => {
      const couponExchangeData = await getAllCouponExchangeSerial()
      const sheetsData = []
      couponExchangeData.forEach((item) => {
        const row = {
          兌換碼: item.code,
        }
        sheetsData.push(row)
      })
      return sheetsData
    }

    const resetExport = () => {
      modal.exporting = false
    }

    const prepareExport = async () => {
      modal.exporting = true
      const data = await getExportData()
      await ExportExcel(data, '兌換碼管理', '兌換碼管理')
    }

    onBeforeRouteLeave((to, from, next) => {
      if (forceRoute.value) next()
      if (nextFuncHolder.value) nextFuncHolder.value()
      store.commit('app/TOGGLE_PAGE_CHANGING', false)
      if (!formData.exchangeLink) {
        modal.beforeLeave = true
        nextFuncHolder.value = next
        return
      }
      store.commit('app/TOGGLE_PAGE_CHANGING', true)
      next()
    })

    return {
      loading,
      formRef,
      formData,
      formRules,
      typeConfig,
      onSubmit,
      goBackCouponExchangeSetting,
      modal,
      resetExport,
      prepareExport,
      nextFuncHolder,
      onConfirmLeave,
    }
  },
})
</script>

<style lang="postcss" scoped>
.coupon-exchange-serial-container {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
.coupon-title {
  @apply min-w-[150px];
}
</style>
